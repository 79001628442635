<template>
    <div class="page-content">
        <div class="grid">
            <div class="page-list-body">
                <div class="row no-gutters page-list-header">
                    <div class="col l-6 m-6 c-12">
                        <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Phải thu khách hàng</span></h4>
                    </div>
                    <div class="col l-6 m-6 c-12">
                        <div class="page-list-action">
                            <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel()" class="md-raised md-primary btn-add">Export<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                            <md-button v-shortkey="['ctrl', 'm']" @shortkey="exportExcel()" @click="exportExcel2()" class="md-raised md-primary btn-add">Export-2<md-tooltip>Xuất file Excel</md-tooltip></md-button>
                            <md-button v-shortkey="['ctrl', 'p']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>P</span>rint<md-tooltip>Print</md-tooltip></md-button>
                            <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                        </div>
                    </div>
                </div>
                <div class="form-body">
                    <div id="print-view" style="width: 1000px; display: flex; flex-direction: column; justify-content: center;margin: 10px auto;">
                        <div style="height: 100px; display: flex; justify-content: space-between;">
                            <div style="border-bottom: 1px solid #999; 
                                width: 25%; display: flex;align-items: center; justify-content: center;
                                border-top: 1px solid #999;border-left: 1px solid #999;">
                                <img style="height: 50px; width: auto;" src="../../assets/images/logo.png" />
                            </div>
                            <div style="border-bottom: 1px solid #999;
                                border-left: 1px solid #999;
                                border-top: 1px solid #999;
                                border-right: 1px solid #999;
                                width: 45%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                text-transform: uppercase;
                                font-weight: bold;">
                                <div style="font-size: 18px;">BẢNG KÊ THU KHÁCH HÀNG</div>
                                <div style="font-size: 15px; padding-top: 10px;">THÁNG {{ entity.monthYear }}</div>
                            </div>
                            <div style="border-top: 1px solid #999; border-right: 1px solid #999;border-bottom: 1px solid #999; width: 30%; display: flex; flex-direction: column; justify-content: space-around; padding: 10px;">
                                <div><span style="font-weight: bold;">Số: </span><span>{{entity.revenueCode}}</span></div>
                                <div><span style="font-weight: bold;">Ngày: </span><span>{{entity.revenueDate}}</span></div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: column; padding: 10px;">
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Khách hàng:</div>
                                <div style="font-size: 16px;">{{ entity.customer.companyName  }}</div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Địa chỉ:</div>
                                <div style="font-size: 16px;">{{ entity.customer.address  }}</div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Mã số thuế:</div>
                                <div style="font-size: 16px;">{{ entity.customer.taxCode  }}</div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="width: 50%; display: flex; align-items: center;">
                                    <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Số tờ khai HQ:</div>
                                    <div style="font-size: 16px;" v-if="entity.order.customs">{{ entity.order.customs.declarationCode  }}</div>
                                </div>
                                <div style="width: 50%; display: flex;">
                                    <div style="display: flex; padding: 5px 0; align-items: center;">
                                        <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Ngày tờ khai:</div>
                                        <div style="font-size: 16px;" v-if="entity.order.customs">{{ entity.order.customs.declarationDate  }}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Mã lô hàng:</div>
                                <div style="font-size: 16px;">{{ entity.order.orderName  }}</div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Số bill/booking:</div>
                                <div style="font-size: 16px;" v-if="entity.order.booking">{{ entity.order.booking.noBooking  }}</div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Số lượng/Mặt hàng:</div>
                                <div style="font-size: 16px;">
                                    <span v-for="(item, index) in entity.goodInfo" :key="'info' - index" style="padding-right: 10px;">
                                        {{ item.number + 'x' + item.unitName + ' - ' + item.goodsName }}
                                    </span>
                                </div>
                            </div>
                            <div style="display: flex; padding: 5px 0;">
                                <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Nội dung:</div>
                                <div style="font-size: 16px;">{{ entity.note  }}</div>
                            </div>
                        </div>
                        
                        <div class="row no-gutters">
                            <div class="col l-12 m-12 c-12" style="padding:20px 0 50px 0;">
                                <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                    <thead>
                                        <tr>
                                            <th style="width:50px; padding: 5px 10px; border: 1px solid #999;">Stt</th>
                                            <th style="width:120px; padding: 5px 10px; border: 1px solid #999;">Tên phí</th>
                                            <th style="width:80px; padding: 5px 10px; border: 1px solid #999;">Đơn vị</th>
                                            <th style="width:60px; padding: 5px 10px; border: 1px solid #999;">Số lượng</th>
                                            <th style="width:100px; padding: 5px 10px; border: 1px solid #999;">Đơn giá</th>
                                            <th style="width:60px; padding: 5px 10px; border: 1px solid #999;">VAT(%)</th>
                                            <th style="width:60px; padding: 5px 10px; border: 1px solid #999;">VAT</th>
                                            <th style="width:100px; padding: 5px 10px; border: 1px solid #999;">Thành tiền</th>
                                            <th style="width:100px; padding: 5px 10px; border: 1px solid #999;">Số HĐ</th>
                                            <th style="width:80px; padding: 5px 10px; border: 1px solid #999;">Ngày HĐ</th>
                                            <th style="width:80px; padding: 5px 10px; border: 1px solid #999;">Ghi chú</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;"><span style="font-weight:bold;">I</span></td>
                                            <td style="padding: 5px 10px; border: 1px solid #999;" colspan="10">
                                                <span style="font-weight:bold;">PHÍ DỊCH VỤ</span>
                                            </td>
                                        </tr>
                                        <tr v-if="transportCost.amount > 0">
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">1</td>
                                            <td style="padding-left: 30px; padding: 5px 10px; border: 1px solid #999;">{{transportCost.feeName}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{transportCost.unitName}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{transportCost.quantity}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(transportCost.price)}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(transportCost.vat)}}%</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{formatNumber((transportCost.price * transportCost.quantity * (transportCost.vat/100)) )}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(transportCost.amount)}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ transportCost.invoiceNumber }}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ transportCost.invoiceDate }}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ transportCost.note }}</td>
                                        </tr>
                                        <tr v-for="(item, index) in entity.details.filter((obj) => obj.type == 3)"  :key="'detail' + item.id">
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{index + 2}}</td>
                                            <td style="padding-left: 30px; padding: 5px 10px; border: 1px solid #999;">{{item.fee.feeName}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{item.unit.unitName}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{item.quantity}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.price)}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.vat)}}%</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.vatAmount)}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.amount)}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ item.invoiceNumber }}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ item.invoiceDate }}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ item.note }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" style="text-align: right; padding: 5px 10px; border: 1px solid #999;"><b>Tổng phí dịch vụ</b></td>
                                            <td style="text-align: right; font-weight: bold; border: 1px solid #999;">{{ formatNumber(entity.feeService) }}</td>
                                            <td colspan="3" style="text-align: right; font-weight: bold; border: 1px solid #999;"></td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;"><span style="font-weight:bold;">II</span></td>
                                            <td style="padding: 5px 10px; border: 1px solid #999;" colspan="10">
                                                <span style="font-weight:bold;">PHÍ CHI HỘ</span>
                                            </td>
                                        </tr>
                                        <tr v-for="(item, index) in entity.details.filter((obj) => obj.type == 2)"  :key="'detail' + item.id">
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{index + 1}}</td>
                                            <td style="padding-left: 30px; padding: 5px 10px; border: 1px solid #999;">{{item.fee.feeName}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{item.unit.unitName}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{item.quantity}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.price)}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.vat)}}%</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.vatAmount)}}</td>
                                            <td style="text-align: right; padding: 5px 10px; border: 1px solid #999;">{{formatNumber(item.amount)}}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ item.invoiceNumber }}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ item.invoiceDate }}</td>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #999;">{{ item.note }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" style="text-align: right; padding: 5px 10px; border: 1px solid #999;"><b>Tổng phí chi hộ</b></td>
                                            <td style="text-align: right; font-weight: bold; border: 1px solid #999;">{{ formatNumber(entity.feeOnBeHalf) }}</td>
                                            <td colspan="3" style="text-align: right; font-weight: bold; border: 1px solid #999;"></td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td colspan="7" style="text-align: right; padding: 5px 10px; border: 1px solid #999;"><b>Tổng cộng</b></td>
                                            <td style="text-align: right; font-weight: bold; border: 1px solid #999;">{{ formatNumber(entity.pay) }}</td>
                                            <td colspan="3" style="text-align: right; font-weight: bold; border: 1px solid #999;"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column; padding: 10px; justify-content: space-between;">
                            <div style="font-weight: bold; font-size: 15px;">GHI CHÚ</div>
                            <div style="padding: 3px 0; font-size: 15px;">1.Đã bàn giao toàn bộ chứng từ gốc theo bảng kê trên</div>
                            <div style="padding: 3px 0; font-size: 15px;">2.Thanh toán cho công ty chúng tôi số tiền trên bằng chuyển khoản theo thông tin sau:</div>
                            <div style="padding: 3px 0 3px 15px; font-size: 15px;">- Công ty thụ hưởng: <span style="text-transform: uppercase; font-weight: bold;">{{ ppl.companyName }}</span></div>
                            <div style="padding: 3px 0 3px 15px; font-size: 15px;">- Số tài khoản: <span style="text-transform: uppercase; font-weight: bold;">{{ ppl.bankNumber }}</span></div>
                            <div style="padding: 3px 0 3px 15px; font-size: 15px;">- Ngân hàng: <span style="text-transform: uppercase; font-weight: bold;">{{ ppl.bankBranch }}</span></div>
                        </div>

                        <div style="display: flex; padding: 10px; justify-content: space-between;">
                            <div style="display: flex; padding: 5px 0; width: 50%; justify-content: center;">
                                <div style="font-weight: bold;font-size: 16px; text-transform: uppercase; padding-bottom: 100px;">{{ entity.customer.companyName }}</div>
                                <div style="font-weight: bold;font-size: 16px; text-transform: uppercase; padding-bottom: 50px;"></div>
                            </div>
                            <div style="display: flex; flex-direction: column; padding: 5px 0; width: 50%; justify-content: center;">
                                <div style="font-weight: bold;font-size: 16px; text-transform: uppercase; padding-bottom: 100px; text-align: center;">{{ ppl.companyName }}</div>
                                <div style="font-weight: bold;font-size: 16px; text-transform: uppercase; text-align: center; padding-bottom: 50px;">{{ entity.staff.fullName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import revenueService from '../../api/revenueService';
    import companyService from '../../api/companyService';
    import messageBox from '../../utils/messageBox';
    import { mapActions } from 'vuex';
    import { APP_CONFIG } from '@/utils/constants';

    export default ({
        metaInfo: {
            title: 'Phải thu khách hàng'
        },
        data() {
            return {
               title: 'Phải thu khách hàng',
               id: 0,
               submitted: false,
               loadding: false,
               details: [],
               entity: { id: 0, staff: { fullName: '' }, customer: { companyName: '' }, details: [] },
               transportCost: { amount: 0 },
               ppl: {}
            }
        },
        created(){
            this.id = this.$route.params.id;
            this.getOwner();
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            exportExcel2(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/revenue/export-revenue-2?id=" + this.id;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/revenue/export-revenue?id=" + this.id;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            print () {
                this.$htmlToPaper('print-view');
            },

            getOwner(){
                this.setLoading(true);
                companyService.getOwner().then((response) => {
                    if(response.statusCode == 200){
                        this.ppl = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                revenueService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.transportCost = { amount: 0 };
                        let feeName = '';
                        let unitName = '';
                        let quantity = 0;
                        let price = 0;
                        let vat = 0;
                        let amount = 0;
                        let invoiceDate = '';
                        let invoiceNumber = '';
                        let note = "";
                        this.entity.details.map(item => {
                            if(item.type == 5){
                                feeName = item.fee.feeName;
                                unitName = item.unit.unitName;
                                price = item.price;
                                vat = item.vat;
                                quantity = quantity + item.quantity;
                                amount = amount + item.amount;
                                invoiceDate = item.invoiceDate;
                                invoiceNumber = item.invoiceNumber;
                                note = item.note;
                            }
                        })
                        this.transportCost = { invoiceDate: invoiceDate, invoiceNumber: invoiceNumber, feeName: feeName, unitName: unitName, price: price, vat: vat, quantity: quantity, amount: amount, note: note };
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/order/crud/' + this.entity.orderId);
            }
        },
    })

</script>

<style scoped>
    .wrapper {
        margin: 0 auto;
        width: 1200px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 26px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 18px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 18px;
    }
    .line span{
        font-size: 18px;
        font-weight: bold;
    }
    .line div {
        font-size: 18px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 200px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 14px;
    }
</style>
